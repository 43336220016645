import React from "react"
import { AppFunctionComponent } from "../../../types"
import styled, { css } from "styled-components"
import RichText from "../../../resolvers/rich-text/rich-text.resolver"
import { ContentfulSection } from "../../../types"
import {
  PrimaryHeading,
  tertiaryHeadingStyles,
} from "../../common/heading.component"
import Section, {
  SectionArticle,
  SectionLayout,
} from "../../common/sections/section.component"

const featureIconsStyles = css`
  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}px) {
    img {
      height: 140px;
      width: 140px;
    }
  }
`

const FeaturesSection = styled(Section)`
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    grid-row-gap: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
    grid-column-gap: 69px;
  }
  ${SectionArticle} {
    max-width: 800px;
  }
`

const Feature = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    margin-top: 0;
    text-align: center;
    white-space: pre-wrap;
    &:first-of-type {
      ${tertiaryHeadingStyles};
      margin: 1em 0;
      min-height: 3em;
      max-width: 245px;

      /* styles should be applied for 768px too */
      @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  ${featureIconsStyles};
`
interface Props {
  content: ContentfulSection
}

const DidYouKnow: AppFunctionComponent<Props> = ({ content }) => {
  const { sectionElement, title } = content

  return (
    <FeaturesSection layout={SectionLayout.ThreeCols}>
      <SectionArticle>
        <PrimaryHeading renderAs={"h2"}>{title}</PrimaryHeading>
      </SectionArticle>
      {sectionElement!.map((featureItem, index) => (
        <Feature key={index}>
          <img src={featureItem.image!.file.url} alt={featureItem.title} />
          <p>{featureItem.title}</p>
          {featureItem.text && <RichText content={featureItem.text} />}
        </Feature>
      ))}
    </FeaturesSection>
  )
}

export default DidYouKnow
