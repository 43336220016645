import React from "react"
import { AppFunctionComponent } from "../../../types"
import styled from "styled-components"
import BackLink from "../../../navigation/components/back-link/back-link.component"
import { MediumSection } from "../../common/sections/section.component"
import { Path } from "../../../navigation/constants/routes"

const BackLinkButton = styled(BackLink)`
  width: max-content;
`

const Section = styled(MediumSection)`
  padding-top: 30px;
`

const BackLinkSection: AppFunctionComponent = () => {
  return (
    <Section>
      <BackLinkButton to={Path.SarChecker}>Back</BackLinkButton>
    </Section>
  )
}

export default BackLinkSection
