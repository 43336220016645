import React from "react"
import { useLocation } from "react-use"
import styled from "styled-components"
import { AppFunctionComponent } from "../../../types"
import Section, {
  SectionArticle,
  SectionImage,
  SectionLayout,
} from "../../common/sections/section.component"
import { ContentfulSection } from "../../../types"
import { SecondaryHeading } from "../../common/heading.component"
import { PhoneData } from "../../../pages-creators/sar-checker-phone.creator"
import { BaseLink } from "../../../ui"
import { Path } from "../../../navigation"

const muditaPureHeadOver10g = 0.07

const InterestingFactSection = styled(Section)`
  background: ${({ theme }) => theme.color.backgroundLightGradient};
`

const MuditaLink = styled(BaseLink)`
  color: ${({ theme }) => theme.color.text};
`

interface Props {
  phoneData: PhoneData
  content: ContentfulSection
}

const InterestingFact: AppFunctionComponent<Props> = ({
  phoneData,
  content,
}) => {
  const { title, image } = content
  const { name, headOver10g } = phoneData

  const sarValueFactor = (headOver10g / muditaPureHeadOver10g).toFixed(2)

  const location = useLocation()

  const pureMessage = (
    <p>
      <MuditaLink to={Path.MuditaPureStore}>Mudita Pure</MuditaLink> have one of
      the safest SAR values in the world ({muditaPureHeadOver10g} W/kg)
    </p>
  )

  const sarMessage = (
    <p>
      {name} SAR value is <strong>{sarValueFactor}</strong> times higher than
      this of a phone with one of the safest SAR values in the world -{" "}
      <MuditaLink to={Path.MuditaPureStore}>Mudita Pure</MuditaLink> (
      {muditaPureHeadOver10g} W/kg)
    </p>
  )

  return (
    <InterestingFactSection layout={SectionLayout.ImageTextBackground}>
      <SectionImage image={image!.gatsbyImageData} disableShadow />
      <SectionArticle>
        <SecondaryHeading>{title}</SecondaryHeading>
        {location.pathname?.includes(Path.SARMuditaPure.toString())
          ? pureMessage
          : sarMessage}
      </SectionArticle>
    </InterestingFactSection>
  )
}

export default InterestingFact
