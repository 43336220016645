import React from "react"
import { AppFunctionComponent } from "../../../types"
import styled from "styled-components"
import { generateSources } from "../../../helpers/images"
import RichText from "../../../resolvers/rich-text/rich-text.resolver"
import { ContentfulSection } from "../../../types"
import Section, {
  SectionImage,
  textImageLayoutTabletGrid,
} from "../../common/sections/section.component"
import { Navigation, Slider, Slides } from "../../common/slider/slider"

const ProtectMyselfSlider = styled(Slider)`
  display: grid;
  grid-template-rows: auto auto 100px;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    ${textImageLayoutTabletGrid};
    grid-template-rows: auto 150px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
    grid-column-gap: 120px;
    grid-template-rows: auto 230px;
  }
`

const SectionElementImage = styled(SectionImage)`
  img {
    object-fit: contain;
  }
`

const ImageSlides = styled(Slides)`
  box-sizing: border-box;
  grid-area: 1 / 1 / 1 / 1;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    grid-area: 1 / 2 / 3 / 2;
    padding: 0;
    margin: 0;
  }
`

const TextSlides = styled(Slides)`
  grid-area: 2 / 1 / 2 / 1;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    text-align: left;
    grid-area: 1 / 1 / 1 / 1;
    padding-top: 50px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
    padding-top: 100px;
  }
`

const SlidesNavigation = styled(Navigation)`
  grid-area: 3 / 1 / 3 / 1;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    justify-content: flex-start;
    align-items: flex-end;
    grid-area: 2 / 1 / 2 / 1;
    padding-bottom: 70px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
    padding-bottom: 150px;
  }
`

interface Props {
  content: ContentfulSection
}

const ProtectMyself: AppFunctionComponent<Props> = ({ content }) => {
  const { sectionElement } = content

  const title = "How can I protect myself?"
  return (
    <Section>
      <ProtectMyselfSlider title={title}>
        <ImageSlides>
          {sectionElement &&
            sectionElement.map(({ responsiveImage }, index) => {
              return (
                <SectionElementImage
                  key={index}
                  image={generateSources([
                    [responsiveImage!.desktopImage],
                    [responsiveImage!.mobileImage, 640],
                  ])}
                  disableShadow
                />
              )
            })}
        </ImageSlides>
        <TextSlides>
          {sectionElement &&
            sectionElement.map(({ text }) => {
              return <>{text && <RichText content={text} />}</>
            })}
        </TextSlides>
        <SlidesNavigation title={title} />
      </ProtectMyselfSlider>
    </Section>
  )
}

export default ProtectMyself
