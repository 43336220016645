import React from "react"
import { AppFunctionComponent, TextBuilder } from "../types"
import { PhoneData } from "../pages-creators/sar-checker-phone.creator"
import Layout from "../components/layout"
import BackLinkSection from "../components/community/sar-checker/back-link-section.component"
import PhoneSarValue from "../components/community/sar-checker/phone-sar-value.component"
import InterestingFact from "../components/community/sar-checker/interesting-fact.component"
import RFRadiation from "../components/community/sar-checker/rf-radiation.component"
import DidYouKnow from "../components/community/sar-checker/did-you-know.component"
import ProtectMyself from "../components/community/sar-checker/protect-myself.component"
import SEO from "../components/seo.component"
import { DetectedBreadcrumbs, TopNavigationWrapper } from "../navigation"
import { graphql } from "gatsby"
import { ContentfulSection } from "../types"
import { Menu } from "../navigation/types/menu.interface"
import { MouseProvider } from "../hooks/use-mouse.hook"

interface Props {
  pageContext: {
    phoneData: PhoneData
  }
  data: {
    interestingFactsContent: ContentfulSection
    radiationContent: ContentfulSection
    didYouKnowContent: ContentfulSection
    protectContent: ContentfulSection
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
  }
}

const SarCheckerPhonePage: AppFunctionComponent<Props> = ({
  pageContext: { phoneData },
  data: {
    interestingFactsContent,
    radiationContent,
    didYouKnowContent,
    protectContent,
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
}) => {
  return (
    <MouseProvider>
      <Layout
        topNavigationItems={topNavigationItems}
        footerNavigationItems={footerNavigationItems}
        promobarContent={promobarContent}
      >
        <SEO title={`SAR Checker - ${phoneData.name} ${phoneData.model}`} />
        <TopNavigationWrapper breadcrumbsMenu={<DetectedBreadcrumbs />} />
        <BackLinkSection />
        <PhoneSarValue phoneData={phoneData} />
        <InterestingFact
          content={interestingFactsContent}
          phoneData={phoneData}
        />
        <RFRadiation content={radiationContent} />
        <DidYouKnow content={didYouKnowContent} />
        <ProtectMyself content={protectContent} />
      </Layout>
    </MouseProvider>
  )
}

export const query = graphql`
  query SarPhonePage($language: String!, $locale: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    interestingFactsContent: contentfulSection(
      contentfulid: { eq: "sar-checker/interesting-fact" }
      node_locale: { eq: $locale }
    ) {
      title
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 100
          width: 560
        )
      }
    }
    radiationContent: contentfulSection(
      contentfulid: { eq: "sar-checker/rf-radiation" }
      node_locale: { eq: $locale }
    ) {
      title
      text {
        raw
      }
    }
    didYouKnowContent: contentfulSection(
      contentfulid: { eq: "sar-checker/did-you-know" }
      node_locale: { eq: $locale }
    ) {
      title
      text {
        raw
      }
      sectionElement {
        title
        text {
          raw
        }
        image {
          file {
            url
          }
        }
      }
    }
    protectContent: contentfulSection(
      contentfulid: { eq: "sar-checker/protect-myself" }
      node_locale: { eq: $locale }
    ) {
      sectionElement {
        text {
          raw
        }
        responsiveImage {
          mobileImage {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              quality: 100
              width: 640
            )
          }
          desktopImage {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              quality: 100
              width: 1260
            )
          }
        }
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "67Y5XVRqB0r7iJbO4N8zxx" }
      node_locale: { eq: $locale }
    ) {
      text {
        raw
      }
      node_locale
    }
  }
`

export default SarCheckerPhonePage
