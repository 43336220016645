import React from "react"
import { AppFunctionComponent } from "../../../types"
import styled from "styled-components"
import RichText from "../../../resolvers/rich-text/rich-text.resolver"
import { ContentfulSection } from "../../../types"
import { PrimaryHeading } from "../../common/heading.component"
import Section, {
  SectionArticle,
  SectionLayout,
} from "../../common/sections/section.component"

const RFRadiationSectionArticle = styled(SectionArticle)`
  --text-max-width: 980px;
  text-align: center;
`

interface Props {
  content: ContentfulSection
}

const RFRadiation: AppFunctionComponent<Props> = ({ content }) => {
  const { title, text } = content

  return (
    <Section layout={SectionLayout.Text}>
      <RFRadiationSectionArticle>
        <PrimaryHeading renderAs={"h2"}>{title}</PrimaryHeading>
        {text && <RichText content={text} />}
      </RFRadiationSectionArticle>
    </Section>
  )
}

export default RFRadiation
