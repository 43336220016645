import React from "react"
import styled from "styled-components"
import { AppFunctionComponent } from "../../../types"
import Section, {
  articleWithBackground,
  SectionArticle,
  SectionImage,
  SectionImageDescription,
  SectionLayout,
} from "../../common/sections/section.component"
import {
  PrimaryHeading,
  primaryHeadingStyles,
  SecondaryHeading,
} from "../../common/heading.component"
import { PhoneData } from "../../../pages-creators/sar-checker-phone.creator"
import { RawLink } from "../../../ui/components/link/link.component"
import { Image } from "../../common/image.component"

const LogoImage = styled(Image)`
  max-width: 125px;
`

const SectionElementImage = styled(SectionImage)`
  img {
    object-fit: contain;
  }
`

const MainSarValue = styled.p``

const DetailsHeading = styled(SecondaryHeading)``
const ModelHeading = styled(SecondaryHeading)``

const PhoneSarValueSection = styled(Section)`
  ${SectionArticle} {
    ${articleWithBackground};
  }

  ${SectionImage} {
    height: auto;
    box-sizing: border-box;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    margin-top: 50px;
  }

  ${PrimaryHeading} {
    margin-top: ${5 / 37}em;
    margin-bottom: 0;
  }

  ${ModelHeading} {
    margin-top: ${15 / 23}em;
    margin-bottom: ${90 / 23}em;
  }

  p {
    margin: 0;
  }

  ${MainSarValue} {
    margin-bottom: ${10 / 23}em;
  }

  p + ${DetailsHeading} {
    margin-top: ${30 / 23}em;
    margin-bottom: ${15 / 23}em;
  }
`

const HeadOver10g = styled.span`
  ${primaryHeadingStyles};
  font-size: ${90 / 16}rem;
  font-weight: 300;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    font-size: ${120 / 16}rem;
  }
`

interface Props {
  phoneData: PhoneData
}

const PhoneSarValue: AppFunctionComponent<Props> = ({
  phoneData: {
    name,
    model,
    headOver10g,
    headOver1g,
    bodyOver10g,
    bodyOver1g,
    sarSourceLink,
    imageSourceLink,
    image,
    logoImage,
  },
}) => {
  return (
    <PhoneSarValueSection layout={SectionLayout.TextImage}>
      <SectionArticle>
        {logoImage && <LogoImage image={logoImage.gatsbyImageData} />}
        <PrimaryHeading>{name}</PrimaryHeading>
        <ModelHeading>{model}</ModelHeading>
        <MainSarValue>
          <HeadOver10g>{headOver10g}</HeadOver10g>
          <PrimaryHeading as="span">W/kg</PrimaryHeading>
        </MainSarValue>
        <p>This result is the Head SAR value, over 10g</p>
        <DetailsHeading>More details about this phone:</DetailsHeading>
        <p>
          Head SAR value over 1g: <strong>{headOver1g}</strong>
        </p>
        <p>
          Body SAR value over 10g: <strong>{bodyOver10g}</strong>
        </p>
        <p>
          Body SAR value over 1g: <strong>{bodyOver1g}</strong>{" "}
        </p>
        <p>
          <RawLink to={sarSourceLink}>Source of SAR value</RawLink>
        </p>
      </SectionArticle>

      {image && (
        <SectionElementImage image={image.gatsbyImageData} disableShadow />
      )}
      <SectionImageDescription>{imageSourceLink}</SectionImageDescription>
    </PhoneSarValueSection>
  )
}

export default PhoneSarValue
